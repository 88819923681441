import React from 'react'
import { Stack, XStack, ZStack } from 'tamagui'
import { BigWhiteBox } from '@centrito/ui/src/components/svgs/outlet/WhiteBox/Big'
import { BigYellowBox } from '@centrito/ui/src/components/svgs/outlet/YellowBox/Big'
import { ContentBoldText, ContentStandardText } from '@centrito/ui/src/components/text/new/content'

export interface BigDiscountTagProps {
  logo: React.ReactNode
  whiteBoxWidth: number
  discount: number
  boxColor?: string
}

const HEIGHT = 28
const OFFSET = 100

export const BigDiscountTag: React.FC<BigDiscountTagProps> = ({
  logo,
  discount,
  whiteBoxWidth,
  boxColor,
}) => {
  const width = whiteBoxWidth + OFFSET

  return (
    <Stack width={width} height={HEIGHT}>
      <ZStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack width={width} justifyContent="flex-end">
              <BigYellowBox height={HEIGHT} />
            </XStack>
            <XStack
              justifyContent="flex-end"
              alignItems="center"
              width={width}
              height={HEIGHT}
              paddingRight={9}
            >
              <ContentStandardText color="$deepShopperBlue">
                OUTLET <ContentBoldText color="$deepShopperBlue">-{discount}%</ContentBoldText>
              </ContentStandardText>
            </XStack>
          </ZStack>
        </XStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack>
              <BigWhiteBox width={whiteBoxWidth} boxColor={boxColor} />
            </XStack>
            <XStack
              width={whiteBoxWidth}
              height={HEIGHT}
              alignItems="center"
              justifyContent="center"
            >
              {logo}
            </XStack>
          </ZStack>
        </XStack>
      </ZStack>
    </Stack>
  )
}

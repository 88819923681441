import React, { type SVGProps } from 'react'

export interface BigWhiteBoxProps extends SVGProps<SVGSVGElement> {
  boxColor?: string
}

export const BigWhiteBox: React.FC<BigWhiteBoxProps> = ({ width, boxColor = '#fff', ...props }) => {
  const fixedHeight = 28 // Keep the height fixed

  // Calculate scaling for dynamic width based on the original viewBox width
  const originalWidth = 92
  const _width = Number(width)
  const scaleFactor = _width / originalWidth

  return (
    <svg
      width={width}
      height={fixedHeight}
      viewBox={`0 0 ${width} ${fixedHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d={`
          M ${0.5 * scaleFactor} 5
          C ${0.5 * scaleFactor} 2.5, ${2.5 * scaleFactor} 0.5, ${5 * scaleFactor} 0.5
          L ${_width - 5.6 * scaleFactor} 0.5
          C ${_width - 2.6 * scaleFactor} 0.5, ${_width - 0.5 * scaleFactor} 3.3, ${
            _width - 1.3 * scaleFactor
          } 6.2
          L ${_width - 6.3 * scaleFactor} 24.2
          C ${_width - 6.9 * scaleFactor} 26.1, ${_width - 8.7 * scaleFactor} 27.5, ${
            _width - 10.7 * scaleFactor
          } 27.5
          H ${5}
          C ${2.5} 27.5, ${0.5} 25.5, ${0.5} 23
          V 5
          Z
        `}
        fill={boxColor}
        stroke="#CECECE"
      />
    </svg>
  )
}

import React from 'react'
import { isClient } from '@centrito/ui/src'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import GefLogo from '@centrito/ui/src/components/svgs/logos/Gef'

export interface GefSmallDiscountTagProps {
  discount: number
}

export const GefSmallDiscountTag: React.FC<GefSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={<GefLogo height={16} {...(!isClient && { width: 23 })} />}
      whiteBoxWidth={35}
      discount={discount}
      boxColor="#000000"
    />
  )
}

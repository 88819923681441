import React, { useContext } from 'react'
import { ChevronRight } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { FilterOverlayContext } from '@centrito/app/components/Feed/FilterBar/Overlay/context'
import { FILTER_OVERLAY_OPTION_GROUP } from '@centrito/app/components/Feed/FilterBar/Overlay/context/constants'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { FILTER_ORDER, SIZED_CATEGORIES } from '@centrito/app/utils/components/Feed'
import { Spinner, XStack, YStack } from '@centrito/ui/src'
import { BoldTextPrimary } from '@centrito/ui/src/components/text'
import { OptionGroup } from './OptionGroup'
import { PriceSlider } from './PriceSlider'

export enum FilterKind {
  CATEGORY = 'category',
  SUPPLIER = 'supplier',
  BRAND = 'brand',
  DEFAULT = 'default',
}

interface OverlayBodyProps {
  kind?: FilterKind
}

const filterUniqueOptions = (options: FilterOverlay.Options): FilterOverlay.Options => {
  const uniqueOptions: Partial<FilterOverlay.Options> = {}

  for (const [key, value] of Object.entries(options)) {
    if (value) {
      const uniqueItems = Array.from(new Map(value.map((item) => [item.name, item])).values())
      uniqueOptions[parseInt(key) as FilterOverlay.OverlayFilterType] =
        uniqueItems as FilterOverlay.OptionItem<number>[]
    }
  }

  return uniqueOptions as FilterOverlay.Options
}

const shouldFilterByCategoryKind = ({
  filterType,
  categoryPrefix,
}: {
  filterType: GetProductsFilterType
  categoryPrefix: string | undefined
}): boolean => {
  const isSizedCategory =
    isNil(categoryPrefix) ||
    SIZED_CATEGORIES.some((category) => categoryPrefix.startsWith(category))

  return (
    filterType !== GetProductsFilterType.CATEGORY &&
    (filterType !== GetProductsFilterType.SIZE || isSizedCategory)
  )
}

export const OverlayBody: React.FC<OverlayBodyProps> = ({ kind = 'default' }) => {
  const { currentFilterDrawer, setCurrentFilterDrawer, options, categoryPrefix } =
    useContext(FilterOverlayContext)

  const uniqueOptions = filterUniqueOptions(options)

  const filterType = currentFilterDrawer as FilterOverlay.OverlayFilterType
  const group = FILTER_OVERLAY_OPTION_GROUP[filterType]
  const items = uniqueOptions?.[filterType]

  if (isNil(group)) {
    return (
      <YStack rowGap={16}>
        {Object.entries(uniqueOptions)
          .sort(([keyA], [keyB]) => {
            const filterTypeA = parseInt(keyA) as GetProductsFilterType
            const filterTypeB = parseInt(keyB) as GetProductsFilterType

            const optionGroupNameA = FILTER_OVERLAY_OPTION_GROUP[filterTypeA]?.optionGroupName ?? ''
            const optionGroupNameB = FILTER_OVERLAY_OPTION_GROUP[filterTypeB]?.optionGroupName ?? ''

            return (
              (FILTER_ORDER[optionGroupNameA] ?? Infinity) -
              (FILTER_ORDER[optionGroupNameB] ?? Infinity)
            )
          })
          .filter(([key]) => {
            const filterType = parseInt(key) as GetProductsFilterType

            if (filterType === GetProductsFilterType.SUPPLIER && categoryPrefix === 'BELLEZA') {
              return false
            }

            switch (kind) {
              case FilterKind.CATEGORY:
                return shouldFilterByCategoryKind({ filterType, categoryPrefix })
              case FilterKind.SUPPLIER:
                return filterType !== GetProductsFilterType.SUPPLIER
              case FilterKind.BRAND:
                return filterType !== GetProductsFilterType.BRAND_ID
              default:
                return true
            }
          })

          .map(([key]) => {
            const type = parseInt(key) as FilterOverlay.OverlayFilterType
            return (
              <XStack
                key={key}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                padding={12}
                onPress={(): void => setCurrentFilterDrawer(type)}
              >
                <XStack alignItems="center" justifyContent="space-between">
                  <YStack>{FILTER_OVERLAY_OPTION_GROUP[type]?.optionGroupIcon}</YStack>
                  <BoldTextPrimary marginLeft={16}>
                    {FILTER_OVERLAY_OPTION_GROUP[type]?.optionGroupName}
                  </BoldTextPrimary>
                </XStack>
                <ChevronRight color="$samsamBlue" size={24} />
              </XStack>
            )
          })}
      </YStack>
    )
  }

  if (filterType === GetProductsFilterType.PRICE_RANGE) {
    return <PriceSlider />
  }

  if (isNil(items)) {
    return (
      <YStack width="100%" flex={1}>
        <Spinner />
      </YStack>
    )
  }
  return <OptionGroup items={items} />
}

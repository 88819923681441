import React from 'react'
import { isClient } from '@centrito/ui/src'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import BabyFreshLogo from '@centrito/ui/src/components/svgs/logos/BabyFresh'

export interface BabyFreshBigDiscountTagProps {
  discount: number
}

export const BabyFreshBigDiscountTag: React.FC<BabyFreshBigDiscountTagProps> = ({ discount }) => {
  return (
    <BigDiscountTag
      logo={<BabyFreshLogo width={125} {...(!isClient && { height: 26 })} />}
      whiteBoxWidth={160}
      discount={discount}
    />
  )
}

import React from 'react'
import { isClient } from '@centrito/ui/src'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import TottoLogo from '@centrito/ui/src/components/svgs/logos/Totto'

export interface TottoBigDiscountTagProps {
  discount: number
}

export const TottoBigDiscountTag: React.FC<TottoBigDiscountTagProps> = ({ discount }) => {
  return (
    <BigDiscountTag
      logo={<TottoLogo width={64} {...(!isClient && { height: 22 })} />}
      whiteBoxWidth={80}
      discount={discount}
    />
  )
}

import React from 'react'
import { isClient } from '@centrito/ui/src'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import FloripondiaLogo from '@centrito/ui/src/components/svgs/logos/Floripondia'

export interface FloripondiaSmallDiscountTagProps {
  discount: number
}

export const FloripondiaSmallDiscountTag: React.FC<FloripondiaSmallDiscountTagProps> = ({
  discount,
}) => {
  return (
    <SmallDiscountTag
      logo={<FloripondiaLogo height={17} {...(!isClient && { width: 75 })} />}
      whiteBoxWidth={100}
      discount={discount}
    />
  )
}

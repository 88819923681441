import { BabyFreshBigDiscountTag } from './BabyFresh/Big'
import { BabyFreshSmallDiscountTag } from './BabyFresh/Small'
import { FloripondiaBigDiscountTag } from './Floripondia/Big'
import { FloripondiaSmallDiscountTag } from './Floripondia/Small'
import { GefBigDiscountTag } from './Gef/Big'
import { GefSmallDiscountTag } from './Gef/Small'
import { LiliPinkBigDiscountTag } from './LiliPink/Big'
import { LiliPinkSmallDiscountTag } from './LiliPink/Small'
import { OffcorssBigDiscountTag } from './Offcorss/Big'
import { OffcorssSmallDiscountTag } from './Offcorss/Small'
import { TottoBigDiscountTag } from './Totto/Big'
import { TottoSmallDiscountTag } from './Totto/Small'

export interface DiscountTag {
  Big: React.FC<{ discount: number }>
  Small: React.FC<{ discount: number }>
}

export const DISCOUNT_TAGS: Record<string, DiscountTag> = {
  TOTTO: {
    Big: TottoBigDiscountTag,
    Small: TottoSmallDiscountTag,
  },
  'LILI PINK': {
    Big: LiliPinkBigDiscountTag,
    Small: LiliPinkSmallDiscountTag,
  },
  OFFCORSS: {
    Big: OffcorssBigDiscountTag,
    Small: OffcorssSmallDiscountTag,
  },
  'BABY FRESH': {
    Big: BabyFreshBigDiscountTag,
    Small: BabyFreshSmallDiscountTag,
  },
  FLORIPONDIA: {
    Big: FloripondiaBigDiscountTag,
    Small: FloripondiaSmallDiscountTag,
  },
  GEF: {
    Big: GefBigDiscountTag,
    Small: GefSmallDiscountTag,
  },
}

import React from 'react'
import { isClient } from '@centrito/ui/src'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import TottoLogo from '@centrito/ui/src/components/svgs/logos/Totto'

export interface TottoSmallDiscountTagProps {
  discount: number
}

export const TottoSmallDiscountTag: React.FC<TottoSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={<TottoLogo width={37} {...(!isClient && { height: 13 })} />}
      whiteBoxWidth={55}
      discount={discount}
    />
  )
}

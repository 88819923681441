import React from 'react'
import { isClient } from '@centrito/ui/src'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import FloripondiaLogo from '@centrito/ui/src/components/svgs/logos/Floripondia'

export interface FloripondiaBigDiscountTagProps {
  discount: number
}

export const FloripondiaBigDiscountTag: React.FC<FloripondiaBigDiscountTagProps> = ({
  discount,
}) => {
  return (
    <BigDiscountTag
      logo={<FloripondiaLogo width={100} {...(!isClient && { height: 25 })} />}
      whiteBoxWidth={120}
      discount={discount}
    />
  )
}

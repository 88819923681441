import React from 'react'
import { isClient } from '@centrito/ui/src'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import BabyFreshLogo from '@centrito/ui/src/components/svgs/logos/BabyFresh'

export interface BabyFreshSmallDiscountTagProps {
  discount: number
}

export const BabyFreshSmallDiscountTag: React.FC<BabyFreshSmallDiscountTagProps> = ({
  discount,
}) => {
  return (
    <SmallDiscountTag
      logo={<BabyFreshLogo height={17} {...(!isClient && { width: 84 })} />}
      whiteBoxWidth={100}
      discount={discount}
    />
  )
}

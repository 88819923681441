import React, { type SVGProps } from 'react'

export const BigYellowBox: React.FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg viewBox="0 0 122 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 5C0 2.23858 2.23858 0 5 0L116.783 0C120.275 0 122.691 3.4879 121.464 6.75686L114.709 24.7569C113.977 26.7077 112.111 28 110.027 28L5 28C2.23858 28 0 25.7614 0 23L0 5Z"
        fill="#FFD149"
      />
    </svg>
  )
}

import React, { type SVGProps } from 'react'

export interface SmallWhiteBoxProps extends SVGProps<SVGSVGElement> {
  boxColor?: string
}

export const SmallWhiteBox: React.FC<SmallWhiteBoxProps> = ({
  width,
  height,
  boxColor = '#fff',
  ...props
}) => {
  const originalWidth = 54
  const originalHeight = 19
  const _width = Number(width)
  const _height = Number(height)
  const scaleX = _width / originalWidth
  const scaleY = _height / originalHeight

  return (
    <svg
      width={_width}
      height={_height}
      viewBox={`0 0 ${_width} ${_height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d={`
          M ${0.5 * scaleX} ${5 * scaleY}
          A ${4.5 * scaleX} ${4.5 * scaleY} 0 0 1 ${5 * scaleX} ${0.5 * scaleY}
          H ${_width - 5.7 * scaleX}
          C ${_width - 2.8 * scaleX} ${0.5 * scaleY}, ${_width - 0.5 * scaleX} ${2.7 * scaleY}, ${
            _width - 1.7 * scaleX
          } ${5.5 * scaleY}
          L ${_width - 3.9 * scaleX} ${14.5 * scaleY}
          A ${4.5 * scaleX} ${4.5 * scaleY} 0 0 1 ${_width - 7.9 * scaleX} ${_height - 0.5 * scaleY}
          H ${5 * scaleX}
          A ${4.5 * scaleX} ${4.5 * scaleY} 0 0 1 ${0.5 * scaleX} ${_height - 5 * scaleY}
          V ${5 * scaleY}
          Z
        `}
        fill={boxColor}
        stroke="#CECECE"
      />
    </svg>
  )
}

import React from 'react'
import { isClient } from '@centrito/ui/src'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import LiliPinkLogo from '@centrito/ui/src/components/svgs/logos/LiliPink'

export interface LiliPinkSmallDiscountTagProps {
  discount: number
}

export const LiliPinkSmallDiscountTag: React.FC<LiliPinkSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={<LiliPinkLogo width={61} {...(!isClient && { height: 19 })} />}
      whiteBoxWidth={80}
      discount={discount}
    />
  )
}

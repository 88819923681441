import React from 'react'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import OffcorsLogo from '@centrito/ui/src/components/svgs/logos/Offcorss'

export interface OffcorssBigDiscountTagProps {
  discount: number
}

export const OffcorssBigDiscountTag: React.FC<OffcorssBigDiscountTagProps> = ({ discount }) => {
  return (
    <BigDiscountTag
      logo={<OffcorsLogo width={77} height={20} />}
      whiteBoxWidth={93}
      discount={discount}
    />
  )
}

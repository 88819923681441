import React from 'react'
import { isClient } from '@centrito/ui/src'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import GefLogo from '@centrito/ui/src/components/svgs/logos/Gef'

export interface GefBigDiscountTagProps {
  discount: number
}

export const GefBigDiscountTag: React.FC<GefBigDiscountTagProps> = ({ discount }) => {
  return (
    <BigDiscountTag
      logo={<GefLogo height={19} {...(!isClient && { width: 35 })} />}
      whiteBoxWidth={45}
      discount={discount}
      boxColor="#000000"
    />
  )
}

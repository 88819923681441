import React from 'react'
import { isClient } from '@centrito/ui/src'
import { BigDiscountTag } from '@centrito/ui/src/components/outlet/BigDiscountTag'
import LiliPinkLogo from '@centrito/ui/src/components/svgs/logos/LiliPink'

export interface LiliPinkBigDiscountTagProps {
  discount: number
}

export const LiliPinkBigDiscountTag: React.FC<LiliPinkBigDiscountTagProps> = ({ discount }) => {
  return (
    <BigDiscountTag
      logo={<LiliPinkLogo height={21} {...(!isClient && { width: 111 })} />}
      whiteBoxWidth={132}
      discount={discount}
    />
  )
}

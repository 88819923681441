import React from 'react'
import { Stack, XStack, ZStack } from 'tamagui'
import { SmallWhiteBox } from '@centrito/ui/src/components/svgs/outlet/WhiteBox/Small'
import { SmallYellowBox } from '@centrito/ui/src/components/svgs/outlet/YellowBox/Small'
import { FootnoteAccentText } from '@centrito/ui/src/components/text/new/footnote'

export interface SmallDiscountTagProps {
  logo: React.ReactNode
  whiteBoxWidth: number
  discount: number
  boxColor?: string
}

const HEIGHT = 19
const OFFSET_BOXES = 30

export const SmallDiscountTag: React.FC<SmallDiscountTagProps> = ({
  logo,
  discount,
  whiteBoxWidth,
  boxColor = undefined,
}) => {
  const width = whiteBoxWidth + OFFSET_BOXES

  return (
    <Stack width={width} height={HEIGHT}>
      <ZStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack width={width} justifyContent="flex-end">
              <SmallYellowBox height={HEIGHT} />
            </XStack>
            <XStack
              justifyContent="flex-end"
              alignItems="center"
              width={width}
              height={HEIGHT}
              paddingRight={5}
            >
              <FootnoteAccentText color="$deepShopperBlack">-{discount}%</FootnoteAccentText>
            </XStack>
          </ZStack>
        </XStack>
        <XStack>
          <ZStack width="100%" height="100%">
            <XStack>
              <SmallWhiteBox height={HEIGHT} width={whiteBoxWidth} boxColor={boxColor} />
            </XStack>
            <XStack
              width={whiteBoxWidth}
              height={HEIGHT}
              alignItems="center"
              justifyContent="center"
            >
              {logo}
            </XStack>
          </ZStack>
        </XStack>
      </ZStack>
    </Stack>
  )
}

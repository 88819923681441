import React from 'react'
import { SmallDiscountTag } from '@centrito/ui/src/components/outlet/SmallDiscountTag'
import OffcorsLogo from '@centrito/ui/src/components/svgs/logos/Offcorss'

export interface OffcorssSmallDiscountTagProps {
  discount: number
}

export const OffcorssSmallDiscountTag: React.FC<OffcorssSmallDiscountTagProps> = ({ discount }) => {
  return (
    <SmallDiscountTag
      logo={<OffcorsLogo width={49} height={13} />}
      discount={discount}
      whiteBoxWidth={64}
    />
  )
}

import isNil from 'lodash.isnil'
import type { ProductReviewPublic } from '@centrito/api/nest/platform/database/domain'
import type { ProductCompositePublicLegacy } from '@centrito/api/nest/shopper/catalog/products/domain/composites'

export const formatPrice = (price: number, isDisplayPositive?: true): string => {
  const priceInCOP = price.toFixed(0)
  if (Number.isInteger(Number(priceInCOP))) {
    return (
      `$${isDisplayPositive ? '+' : ''}` +
      `${priceInCOP}`.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.')
    )
  } else {
    return `$${isDisplayPositive ? '+' : ''}${priceInCOP}`
  }
}
export const parseCOPToNumber = (copString: string): number => {
  const withoutCurrency = copString.replace(/[$\s]/g, '')
  const withoutThousandSeparators = withoutCurrency.replace(/\./g, '')
  const normalized = withoutThousandSeparators.replace(',', '.')
  return parseFloat(normalized)
}

export const getPercentageDiscount = (
  product: ProductCompositePublicLegacy | Omit<ProductCompositePublicLegacy, 'variants'>,
): number => {
  if (isNil(product.highestCampaign)) {
    return 0
  }
  const campaignRules = product.highestCampaign?.rules || null

  if (isNil(campaignRules)) {
    return 0
  }
  if (campaignRules.discount.type === 'AMOUNT') {
    return Math.floor(
      (campaignRules.discount.amount * 100) / product.product.pricingData.priceSubtotal,
    )
  }
  if (campaignRules.discount.type === 'PERCENTAGE') {
    return campaignRules.discount.amount * 100
  }
  return 0
}

export const getAverageReviewInfo = (productReviews: ProductReviewPublic[]): [number, number] => {
  if (productReviews.length === 0) {
    return [0, 0]
  }

  const [totalScore, countWithComments] = productReviews.reduce(
    ([accScore, accComments], review) => [
      accScore + review.score,
      accComments + (!isNil(review.comment) ? 1 : 0),
    ],
    [0, 0],
  )

  const averageScore = totalScore / productReviews.length
  return [+averageScore.toFixed(1), countWithComments]
}

export const getReviewCountByScore = (productReviews: ProductReviewPublic[]): number[] => {
  // Initialize an array to store counts of scores 5 through 1
  const scoreCounts = new Array(5).fill(0)

  // Iterate over each product review
  for (let i = 0; i < productReviews.length; i++) {
    const score = productReviews[i].score
    if (score >= 1 && score <= 5) {
      scoreCounts[score - 1]++
    }
  }

  return scoreCounts.reverse()
}
